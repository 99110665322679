.container {
    padding : 0rem  3rem ;
    position : relative ;
    display : flex ;
    flex-direction: column;
    gap : 3rem ;
    align-items : center ;
    justify-content: center ;
}

.container>:first-child{
    position: absolute ;
    width : 8rem ; 
    left : 20% ;
    top : -3rem ; 
}
.container>:nth-child(2){
    position: absolute  ;
    width : 30rem ;
    left : 30% ;
    top : -3rem ;
}

.products {
    display : grid ;
    width : 90% ;
    grid-template-columns:  25% auto ;  
    padding : 5rem  
}

.menu {
    list-style: none ;
    display : flex ;
    flex-direction: column;
    gap : 2rem ;
    font-weight: 600;
    font-size: 1.3rem ;
    
}
.menu>li:hover {
    color : pink ;
    cursor : pointer ;
}

.list {
    display : grid ;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
    height :25rem ;
     overflow-y : scroll ;
    overflow-x: hidden ;
    grid-gap : 2rem ;
    justify-content: space-between;
}

.product {
    width : 12rem  ;
    height : 6rem ;
    background-color: white ;
    position: relative ;
    overflow:hidden ;
    padding : 1rem ;
    display : flex ;
    border-radius: 1rem ;


}
 .product>img {
    top : 3rem ;
    width : 6rem ;
    height : 11rem ;
    transform: rotate(-20deg) ;
    position: absolute;
    right: 0;
    width: 40%;
    height: 100%;
    bottom: -20%;
 }

