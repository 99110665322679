.container {
    padding : 1.5rem ; 
    display : flex ;
}

.logo {
    display: flex;
    flex: 1 ;
    gap : 1.5rem ;
}

.logo>img{
    width : 3rem ;
    height: 3rem ;
}
.logo>span{
    margin-top: 1rem ;
    font-weight: 600;
   
}

.right {
    display: flex ;
    align-items: center ;
    gap : 2rem ;
    justify-content: center;
    
}

.menu {
    display: flex;
    gap : 2rem ;
    font-weight: 500;
    list-style-type: none ;
}
.menu>li:hover{
    color: rgb(224, 134, 149) ;
    cursor: pointer ;
}
.search {
    height: auto ;
    width: 8rem ;
    border : none ;
    outline : none ;
    padding : .4rem ;
}
.cart {
    width : 1.5rem ;
    height: 1.5rem ;
    cursor:pointer ;
}

