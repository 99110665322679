.container {
    padding : 0rem  2rem ;
    display: grid ;
    grid-template-columns: 1fr 4fr 1fr ;
    
}

.wrapper {
    display:flex ;
    align-items: baseline;
    justify-content: center ;
    position :relative ;
}

.wrapper>img {
   position :absolute ;
    width : 27rem ;
    bottom : 0rem ;
}

.h_sides {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1 {
    text-transform: uppercase;
    font-size:1.5rem ;
    font-weight: 600 ;
    width :min-content ;
    display :block ;
}
.text2 {
    display : flex ;
    flex-direction: column ;
    width : min-content ;
    color :rgb(116, 20, 20) ;
}
.text2>:first-child {
    font-weight: 800 ;
    font-size : 2.5rem ;
}

.text2>:last-child{
    display: block ;
}

.traffic {
    display : flex ;
    flex-direction : column;
    text-align: right ;

}

.traffic>:first-child {
    font-weight: 800 ;
    font-size: 2.5rem ;
}

.customers{
    display: flex ;
    flex-direction: column ;
    text-align : right  ;
}

.customers>:first-child{
    font-weight: 800 ;
    font-size : 2rem ;
}
.blueCircle {
    width: 30rem ;
    height : 30rem ;
    border-radius: 50% ;
    z-index : -99 ;
    position: absolute;
    bottom: 0rem ;
    background: linear-gradient(31.78deg , rgb(37, 227, 244) , white )
}
.cart2 {
    position: absolute ;
    bottom : 15% ;
   right : 0% ;
    display : flex ;
    gap: 1rem ; 
    align-items: center ;
}
.cart2>svg {
    width : 30px ;
    height: 30px ;
    background: white ;
    padding : 10px ;
border-radius: 50% ;
border :  6px solid var(--black) ;
}

.signup {
    display : flex ;
    background-color: aliceblue;
    padding : 10px ;
    border-radius: 15px ;
     box-shadow:  0px 13px 48px -1px rgba(0, 0, 0, 0.75); 
    align-items: center ;
    gap : 1rem  ;
    font-size: 1rem ;
}

.signup>:first-child{
    display: block;
}
.signup>:nth-child(2){
    border-radius: 50%;
    border : 1px solid blue;
    display: flex ;
    width : 20px ;
    height: 20px ;
    align-items: center;
    padding: 5px;
    justify-content: center;
}


